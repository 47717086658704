<template>
  <div>
    <h1>Game List</h1>

    <div class="filter-container">
      <label for="players">Players:</label>
      <input type="number" max="100" v-model.number="filters.players" id="players" />

      <label for="quick">Quick Game:</label>
      <input type="checkbox" v-model="filters.quick" id="quick" />

      <!-- Genre Filter Dropdown Button -->
      <div class="dropdown">
        <button @click="toggleGenreDropdown" class="dropdown-button">
          Select Genres
        </button>
        <div v-show="isGenreDropdownVisible" class="dropdown-content">
          <div v-for="genre in genres" :key="genre">
            <input
                type="checkbox"
                :value="genre"
                v-model="filters.genres"
                :id="`genre-${genre}`"
            />
            <label :for="`genre-${genre}`">{{ genre }}</label>
          </div>
        </div>
      </div>

      <button @click="applyFilters" class="apply-filters-button">Apply Filters</button>
    </div>

    <ul>
      <li v-for="game in filteredGames" :key="game.id">
        <router-link :to="{ name: 'GameDetail', params: { boardgamegeekid: game.bgg_id }}">
          {{ game.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<style>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  color: white;
  text-decoration: none;
  padding: 8px;
  margin: 5px 0;
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
}

.dropdown-content div {
  margin-bottom: 8px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    gap: 8px;
  }

  .dropdown-button {
    font-size: 14px;
  }

  .apply-filters-button {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  li {
    padding: 6px;
    font-size: 14px;
  }

  .dropdown-button {
    font-size: 12px;
    padding: 8px;
  }

  .apply-filters-button {
    padding: 8px;
  }
}
</style>

<script>
import axios from 'axios'
// import toastr from 'toastr'

export default {
  data() {
    return {
      games: [],
      genres: ['World Builder', 'Worker Placement', 'Strategy', 'Engine Builder', 'Puzzle', 'Perfect Information', 'Combat', 'Asymmetric', 'Speed', 'Roll \'n\' Write', 'Resource Management', 'Co-Op', 'Money', 'Party', 'Pen and Paper', 'Gotcha', 'Deck Builder', 'Bluffing', 'TCG', 'Balance', 'Program', 'Set Collection', 'Silly'],
      filters: {
        players: null,
        quick: false,
        genres: [] // This will hold selected genres as an array
      },
      isGenreDropdownVisible: false // Track the visibility of the genre dropdown
    }
  },
  computed: {
    filteredGames() {
      return this.games.filter(game => {
        if (this.filters.players < 0) {
          this.filters.players = 0;
          // toastr.error('???', "What are ya doin!")
        }

        let matchesPlayers = true;
        if (this.filters.players !== null && this.filters.players !== undefined) {
          matchesPlayers = game.min_players <= this.filters.players && game.max_players >= this.filters.players;
        }

        const matchesQuick = this.filters.quick
            ? game.quick === this.filters.quick
            : true;

        let matchesGenre = true;
        if (this.filters.genres.length > 0) {
          matchesGenre = this.filters.genres.some(genre => game.gametype.split(',').includes(genre.toLowerCase()));
        }

        if (this.filters.players === 0) {
          matchesPlayers = true;
        }

        return matchesPlayers && matchesQuick && matchesGenre;
      });
    }
  },
  async created() {
    try {
      const response = await axios.get('https://meadgamers.pockethost.io/api/collections/games/records?perPage=1000&sort=+name,bgg_id')
      this.games = response.data.items
    } catch (error) {
      console.error('Error fetching games:', error)
    }
  },
  methods: {
    applyFilters() {
      // This will automatically update the displayed games because filteredGames is a computed property
    },
    toggleGenreDropdown() {
      this.isGenreDropdownVisible = !this.isGenreDropdownVisible;
    }
  }
}
</script>
