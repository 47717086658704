import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GamePage from '../views/GamePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/:boardgamegeekid', // Correct path with parameter
    name: 'GameDetail',
    component: GamePage,
    props: true // Enables passing route params as props to the component
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
