<template>
  <div v-if="game && bggData">
    <h1>{{ game.name }}</h1>
    <div class="gamescontainer-1">
      <div class="gamecontainer">
        <img :src="bggData.imageURL" alt="Game Image" />
        <div class="gamecontainer2">
          <p>Location: {{ game.location }} </p>
          <p>Players: {{ game.min_players }} - {{ game.max_players }}</p>
          <p id="desc" v-html="bggData.description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.gamescontainer-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px; /* Ensure there's some padding */
}

#desc {
  border: 1px solid black;
  padding: 2px;
}

.gamecontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

img {
  border-right: 5px solid black;
  padding: 10px;
}

.gamecontainer2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .gamecontainer {
    flex-direction: column;
    align-items: flex-start;
  }

  img {
    border-right: none;
    border-bottom: 5px solid black;
    width: 100%;
    height: auto;
    padding: 5px;
  }

  .gamecontainer2 {
    align-items: flex-start;
    text-align: left;
  }

  #desc {
    border: none;
    padding: 0;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5em;
  }

  .gamecontainer {
    gap: 10px;
  }

  img {
    padding: 3px;
  }

  .gamecontainer2 p {
    font-size: 0.9em;
  }
}
</style>

<script>
import axios from 'axios'

export default {
  props: ['boardgamegeekid'],
  data() {
    return {
      game: null,
      bggData: null
    }
  },
  async created() {
    try {
      let gameResponse
      try {
        gameResponse = await axios.get(`https://meadgamers.pockethost.io/api/collections/games/records?filter=(bgg_id=${this.boardgamegeekid})`)
      } catch (err) {
        console.error('Error fetching game details:', err)
        return
      }
      this.game = gameResponse.data.items[0]

      const bggId = this.game.bgg_id
      const bggResponse = await axios.get(`https://boardgamegeek.com/xmlapi2/thing?id=${bggId}`)
      const parser = new DOMParser()
      const xml = parser.parseFromString(bggResponse.data, 'text/xml')
      this.bggData = {
        imageURL: xml.getElementsByTagName('image')[0]?.textContent || '',
        description: formatDescription(xml.getElementsByTagName('description')[0]?.textContent || ''),
      }
    } catch (error) {
      console.error('Error fetching game details:', error)
    }
  }
}

function formatDescription(description) {
  return description
      .replace(/&#10;/g, '<br>')
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
}
</script>
